import React from 'react';
import * as styles from './CaseStudyMediaObject.module.scss';
import classNames from 'classnames';
import {SVGSupportImg} from "../SVGSupportImg";
import {MoreLink} from "../MoreLink";
import {TypographyV2} from "../TypographyV2";
import {LangLink} from "../LangLink";

export function CaseStudyMediaObject({title, children, slug, image, logo, className, ...otherProps}) {
    const url = `/case-study/${slug}/`;
    return <LangLink className={classNames(className, styles.root)} to={url} {...otherProps}>
        {image ? <SVGSupportImg {...image} className={styles.image} alt=""/> : undefined}
        <div className={styles.contentWrapper}>
            <SVGSupportImg {...logo} className={styles.logo} alt="" objectFit={"contain"} objectPosition={"left"}/>
            <TypographyV2 variant={"h5"} isBold={true} tag={"h2"} className={styles.title}>{title}</TypographyV2>
            <MoreLink component={"span"} className={styles.moreLink}>Read more</MoreLink>
        </div>
    </LangLink>
}
