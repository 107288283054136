import * as styles from './CaseStudiesGrid.module.scss';
import classNames from 'classnames';
import React from 'react';

export function CaseStudiesGrid({className, children, ...otherProps}) {
    return <div className={classNames(styles.root, className)} {...otherProps}>
        {React.Children.map(children, child => {
            return React.cloneElement(child, {
                ...child.props,
                className: classNames(child.props.className, styles.item)
            });
        })}
    </div>
}