import React from "react"
import {Layout} from "../components/Layout";
import {PageMainColumnWrapper} from "../components/PageMainColumnWrapper";
import {graphql} from "gatsby";
import {BackgroundLine} from "../components/BackgroundLine";
import {injectIntl} from 'gatsby-plugin-react-intl';
import {CaseStudiesBlock} from "../components/CaseStudiesBlock";
import {createSeoData} from "../functions/createSeoData";
import {Sections} from "../components/Sections";
import {SectionHero} from "../components/SectionHero";
import * as styles from "../components/CaseStudyHero/CaseStudyHero.module.scss";
import {Breadcrumbs} from "../components/Breadcrumbs";

export const query = graphql`
    query CaseStudies($locale: String!) {
        caseStudies: allStrapiCaseStudy(filter: {locale: {eq: $locale}}) {
            edges {
                node {
                    title
                    slug
                    intro {
                        data {
                            childMarkdownRemark {
                                html
                            }
                        }
                    }
                    logo {
                        localFile {
                            url
                            childImageSharp {
                                gatsbyImageData(
                                    height: 50,
                                    placeholder: NONE
                                    outputPixelDensities: [1, 2, 4],
                                    transformOptions: {fit: CONTAIN }
                                    layout: CONSTRAINED,
                                    backgroundColor: "transparent"
                                )
                            }
                        }
                    }
                    image {
                        localFile {
                            url
                            childImageSharp {
                                gatsbyImageData(
                                    width: 400
                                    height: 268,
                                    placeholder: NONE
                                    outputPixelDensities: [1, 2, 4],
                                    transformOptions: {cropFocus: NORTHWEST, fit: COVER }
                                    layout: CONSTRAINED,
                                )
                            }
                        }
                    }
                }
            }
        }
        page: strapiSpecialPage(locale: {eq: $locale}, slug: {eq: "case-study"}) {
            title
            hero {
                ...SectionHero

                imageOverride: image {
                    localFile {
                        url
                        childImageSharp {
                            gatsbyImageData(
                                width: 596
                                placeholder: NONE
                                outputPixelDensities: [1, 2, 4],
                                transformOptions: {cropFocus: NORTHWEST, fit: CONTAIN }
                                layout: CONSTRAINED

                            )
                        }
                    }
                }
            }
            sections {
                ...AllSectionsContent
            }
            seo {
                ...Seo
            }
        }
    }
`

function CaseStudies({data, intl, pageContext}) {
    const hero = data.page.hero;
    return <Layout services={pageContext.services}
                   oldServices={pageContext.oldServices}
                   linkableSubServices={pageContext.linkableSubServices}
                   title={intl.formatMessage({id: 'menu.caseStudy'})}
                   jobsCount={pageContext.jobsCount}
                   seo={createSeoData(data.page.seo)}
                   companyData={pageContext.companyData}
                   background={<>
                       <BackgroundLine mobileOn={false}/>
                   </>}
                   lang={pageContext.lang}>
        <PageMainColumnWrapper>
            {hero && <SectionHero
                title={hero.title}
                content={hero.content.data}
                ctaButtonRef={hero.ctaButtonRef}
                breadcrumbs={
                    <Breadcrumbs className={styles.breadcrumbs}>
                        <Breadcrumbs.Item to="/">{intl.formatMessage({id: 'menu.home'})}</Breadcrumbs.Item>
                        <Breadcrumbs.Item>{intl.formatMessage({id: 'menu.caseStudies'})}</Breadcrumbs.Item>
                    </Breadcrumbs>
                }
                alignContent="center"
                mediaMaxHeight={760}
                image={hero.imageOverride?.localFile}/>}
            <CaseStudiesBlock
                caseStudies={data.caseStudies.edges.map(x => {
                    return {
                        title: x.node.title,
                        logo: x.node.logo?.localFile,
                        image: x.node.image?.localFile,
                        description: x.node.intro.data,
                        slug: x.node.slug
                    };
                })}
            />
        </PageMainColumnWrapper>
        <Sections
            sections={data.page.sections}
        />
    </Layout>
}

export default injectIntl(CaseStudies);
